import { RelayProduct, AvailableProducts, Product } from '../types'
import { StorageManager } from '@truepill/tph-questionnaire'

interface ProductDict {
  [key: string]: Product
}

export function pullProducts(relayProducts: RelayProduct[]): [AvailableProducts, { [key: string]: Product }] {
  return relayProducts.reduce(
    (acc: [AvailableProducts, ProductDict], relayProduct) => {
      const [available, productDict] = acc
      const { edges } = relayProduct
      for (const edge of edges) {
        const product = {
          ...edge.node,
        }
        if (!available[product.productName.productName]) {
          available[product.productName.productName] = { ...product, versions: [] }
        }
        if (!productDict[product.id]) {
          productDict[product.id] = product
        }
        const productEntry = available[product.productName.productName]
        productEntry.versions.push(product)
        productEntry.versions.sort((aProduct, bProduct) => {
          const aQuantity = aProduct.numberOfUses
          const bQuantity = bProduct.numberOfUses
          if (aQuantity > bQuantity) {
            return 1
          } else if (aQuantity < bQuantity) {
            return -1
          }
          return 1
        })
      }

      return [available, productDict]
    },
    [{}, {}]
  )
}

type PatientAccessToken = {
  token: string
  consultId: string
}

export const getPatientAccessToken = (): PatientAccessToken | undefined => {
  let token = null
  let consultId = null
  if (typeof window !== 'undefined') {
    const params = new URLSearchParams(window.location.search)
    token = params.get('token')
    consultId = params.get('consultId')
  }

  // If we have a valid token in our params, use it and store it for later
  if (token && token.length && consultId && consultId.length) {
    const newCredentials = JSON.stringify({
      token,
      consultId,
    })

    StorageManager.setItem('patientAccessCredentials', newCredentials)
    return { token, consultId }
  }

  // otherwise search localStorage for one
  const storedTokenData = StorageManager.getItem('patientAccessCredentials')
  if (storedTokenData) {
    const parsedTokenData = JSON.parse(storedTokenData)
    return { token: parsedTokenData.token, consultId: parsedTokenData.consultId }
  }
}
