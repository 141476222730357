import { gql } from '@apollo/client'

export const CHECK_ANSWER_QUALIFICATION = gql`
  query QualifyAnswers($answers: HumanaEDAnswersInput) {
    qualifyAnswers(answers: $answers)
  }
`

export const GET_PATIENT_DATA = gql`
  query GetPatientData($consultId: ID!) {
    getPatientConsult(id: $consultId) {
      patient {
        firstName
        lastName
        contacts {
          phone
          email
          sms
        }
        standardQuestions {
          answers {
            medications
            allergies
            conditions
          }
        }
      }
      urac {
        medications
        allergies
        conditions
      }
      shippingAddress {
        line1
        line2
        street1
        street2
        state
        city
        zip
      }
      payment {
        shippingOption
        customerToken
      }
      documents {
        name
        document {
          s3 {
            key
            bucket
          }
        }
      }
    }
  }
`
