import { gql } from '@apollo/client'

export const ADD_QUESTIONNAIRE_DEMOGRAPHIC = gql`
  mutation createQuestionnaireDemographic($patient: PatientInput, $status: String, $domain: String) {
    createQuestionnaireDemographic(patient: $patient, status: $status, domain: $domain)
  }
`

export const UPDATE_QUESTIONNAIRE_DEMOGRAPHIC = gql`
  mutation updateQuestionnaireDemographic(
    $id: ID
    $patient: PatientInput
    $answers: HumanaEDAnswersInput
    $status: String
    $domain: String
    $consult: ID
  ) {
    updateQuestionnaireDemographic(id: $id, patient: $patient, answers: $answers, status: $status, domain: $domain, consult: $consult)
  }
`

export const CREATE_CONSULT = gql`
  mutation createConsult(
    $photos: PhotoIDInput
    $answers: HumanaEDAnswersInput
    $patient: PatientInput
    $shippingAddress: ShippingAddressInput
    $prescriptionPreference: PrescriptionPreferenceInput
  ) {
    createHumanaConsult(
      photos: $photos
      answers: $answers
      patient: $patient
      shippingAddress: $shippingAddress
      prescriptionPreference: $prescriptionPreference
    )
  }
`

export const UPDATE_CONSULT_PATIENT = gql`
  mutation updateHumanaConsultPatient(
      $id: ID!,
      $standardQuestions: StandardQuestionsInput,
      $shippingAddress:  ShippingAddressInput
      $payment: PatientPaymentInput
  ) {
    updateHumanaConsultPatient(
      id: $id, 
      standardQuestions:$standardQuestions,
      shippingAddress: $shippingAddress
      payment: $payment
    ) {
      id
      standardQuestions {
        medications
        allergies
        conditions
      }
      shippingAddress {
        line1
        line2
        street1
        street2
        city
        state
        zip
      }
    }
  }
`

export const UPDATE_CONSULT_PHOTO = gql`
  mutation updateHumanaConsultPhoto($id: ID!, $photos: PhotoIDInput) {
    updateHumanaConsultPhoto(id: $id, photos: $photos)
  }
`

export const GENERATE_HIPAA_AGREEMENT = gql`
  mutation generateHIPPAAgreement($id: ID!) {
    generateHIPAA(id: $id)
  }
`

export const UPLOAD_PHOTO = gql`
  mutation uploadPhoto(
    $document: DocumentInput!
  ) {
    uploadPhoto(
      document: $document
    )
  }
`