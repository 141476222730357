import { ApolloLink, ApolloClient, InMemoryCache, concat, HttpLink } from '@apollo/client'
import fetch from 'isomorphic-fetch'
import { getPatientAccessToken } from '../utils/graphql'

const localOverride = process.env.GATSBY_REACT_APP_LOCAL
const resolvedURI = !process.env.GATSBY_REACT_APP_LOCAL ? `https://truehealth.falsepill.com/graphql` : localOverride
const httpLink = new HttpLink({ uri: resolvedURI, fetch });
const AUTH_TOKEN = process.env.GATSBY_AUTH_TOKEN

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      Authorization: `Bearer ${AUTH_TOKEN}`,
    },
  })

  return forward(operation)
})

const patientAccessAuthMiddleware = new ApolloLink((operation, forward) => {
  const patientAccessToken = getPatientAccessToken()
  let token = null
  let consultId = null
  if (patientAccessToken) {
    token = patientAccessToken.token
    consultId = patientAccessToken.consultId
  }
  operation.setContext({
    headers: {
      Authorization: `Bearer ${token}.${consultId}`,
    },
  })

  return forward(operation)
})

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, httpLink),
})

export const patientAccessClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(patientAccessAuthMiddleware, httpLink),
})

export default client
